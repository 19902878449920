input {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  &--blue {
    color: #4CBFFF;
  }
  &--primary {
    color: #3BDFCF;
  }
  &--bold {
    font-weight: 600;
  }
  &--semi-bold {
    font-weight: 700;
  }
  &--inline {
    display: inline;
  }
}

.error-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 13px;
  color: #ed5f68;
}

.app-table {
  background: linear-gradient(1.21deg, rgba(3, 6, 13, 0.5) 4.95%, rgba(3, 7, 14, 0.489583) 52.19%, rgba(29, 42, 63, 0.135384) 158.95%, rgba(38, 55, 82, 0) 246.08%);
  border: 1px solid #5BBDE4;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  height: 663px;
  box-sizing: border-box;

  table {
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;

    thead {
      display: table-header-group;
      vertical-align: middle;
      background: var(--secondary-black-color);
      border-bottom: 1px solid var(--secondary-blue-color);
      height: 52px;
      text-align: left;
      box-sizing: border-box;
      overflow: hidden;

      th {
        padding: 18px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        &:first-of-type {
          border-radius: 6px 0 0 0;
          overflow: hidden;
        }
        &:last-child {
          border-radius: 0 0 0 6px;
          overflow: hidden;
        }
      }
    }
    tr {
      border-radius: 6px;
      height: 60px;
    }
    tbody {
      display: table-row-group;
      vertical-align: middle;
      background-color: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      .semi-bold {
        font-weight: 500;
      }

      tr {
        border-bottom: 1px solid rgb(19,27,41);
        transition: 0.3s ease all;
        &:hover {
          background-color: #334157;
        }
        td {
          padding: 0 18px;
        }
      }
    }
  }
}

.app-paper {
  background: #192230;
  border-radius: 10px;
  padding: 18px 22px;
}

.loading {
  position: relative;
  overflow: hidden;
  cursor: wait;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(180deg, rgba(#151C27, 0.5) 0%, rgba(#000000, 0.4) 100%);
    animation: shimmer 2s infinite;
    content: '';
  }
  &--transparent:after {
    background-image: none;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.measurements-table {
  box-sizing: border-box;
  table {
    display: grid;
    width: 100%;
    tr {
      cursor: pointer;
      display: contents;
    }
    thead {
      background-color: #000;
      padding: 8px 0;
      display: grid;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      text-align: left;
      grid-template-columns:
        minmax(20px, 1fr)
        minmax(20px, 2fr)
        minmax(20px, 2.5fr)
        minmax(20px, 1.5fr)
        minmax(20px, 1.5fr)
        minmax(20px, 1.5fr);
    }
    tbody {
      display: grid;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      text-align: left;
      background: linear-gradient(
                      1.21deg,
                      rgba(3, 6, 13, 0.5) 4.95%,
                      rgba(3, 7, 14, 0.489583) 52.19%,
                      rgba(29, 42, 63, 0.135384) 158.95%,
                      rgba(38, 55, 82, 0) 246.08%
      );
      grid-template-columns:
        minmax(20px, 1fr)
        minmax(20px, 2fr)
        minmax(20px, 2.5fr)
        minmax(20px, 1.5fr)
        minmax(20px, 1.5fr)
        minmax(20px, 1.5fr);
    }
    th {
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        text-align: center;
      }
    }
    td {
      align-items: center;
      padding: 6px 0;
      border-bottom: 1px solid #353f4f;
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        text-align: center;
        border-left: 1px solid #353f4f;
      }
      &:nth-of-type(3) {
        color: rgb(59, 223, 207);
      }
      &:nth-of-type(5) {
        color: rgb(237, 95, 104);
      }
    }
    tr:last-of-type td {
      border-bottom: none;
    }
  }
  &--digitize {
    table {
      thead {
        grid-template-columns:
        minmax(10px, 0)
        minmax(20px, 3fr)
        minmax(20px, 1.2fr)
        minmax(20px, 1.3fr)
        minmax(20px, 1.2fr)
        minmax(20px, 1fr);
      }
      tbody {
        grid-template-columns:
        minmax(10px, 0)
        minmax(20px, 3fr)
        minmax(20px, 1.2fr)
        minmax(20px, 1.3fr)
        minmax(20px, 1.2fr)
        minmax(20px, 1fr);
      }
    }
  }
  .title {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #5bbde4;
    //background-color: #000;
    padding: 8px 0 8px 10px;
    margin: 0;
    background-color: #192230;
  }
}

.app-info-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  &.active {
    display: flex;
    z-index: 5;
  }
  &__paper {
    box-sizing: border-box;
    width: 100%;
    max-width: 496px;
    background: linear-gradient(0.07deg, rgba(3, 6, 13, 0.5) 35.24%, rgba(38, 55, 82, 0) 290.72%);
    border: 1px solid #51A7CA;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    padding: 48px 46px;
    text-align: center;
    position: relative;
    p {
      margin: 0;
    }
  }
  &__close {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: none;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }
  &__footer {
    margin-top: 42px;
  }
}

.app-tabs {
  padding: 0;
  margin: 0;
  display: flex;
  li {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    transition: all 0.3s ease;
    border: 1px solid #353f4f;
    cursor: pointer;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7c8ba2;
      transition: all 0.3s ease;
    }
    &.activeTab {
      border-color: #5bbde4;
      span {
        color: #fff;
      }
    }
  }
}

*::-webkit-scrollbar-track
{
  width: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar
{
  width: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background: rgba(85, 100, 126, 0.61);
}

#SfxPopper {
  color: #fff;
}
.SfxCrossButton-root {
  background-color: transparent !important;
}
.SfxIconButton-root {
  background-color: transparent;
  color: #fff;
}